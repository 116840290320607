var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "memory-charts-wrapper" }, [
    _c("div", {
      staticClass: "chart",
      attrs: { id: `aggregate-${_vm.chartId}` },
    }),
    _c("div", { staticClass: "chart", attrs: { id: _vm.chartId } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }